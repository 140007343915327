import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Matt Hillsdon`}</h1>
    <p>{`I'm a software developer working for the `}<a parentName="p" {...{
        "href": "https://microbit.org"
      }}>{`Micro:bit Educational Foundation`}</a>{` in Oxford.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      